.search-select {
  .ce-ant-select-selection-overflow-item {
    background-color: #e8ebf1;
    border-radius: 8px;
    margin-right: 4px;
    .ce-ant-select-selection-item {
      background-color: #e8ebf1;
      border: none;
    }
  }
  .ce-ant-select-selection-overflow-item-suffix {
    background-color: initial;
  }
}

@primary-color: #5B9DFF;@ant-prefix: ce-ant;
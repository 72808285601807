@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/fonts/index.css";

@import "~antd/dist/antd.less";
@import "./theme.less";


#sub-react-root {

  height: inherit;

  font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .App {
    font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 16px;
    background: #fafbff;
    height: inherit;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }


  .manage-criteria-form{
    .ce-ant-form-item-row{
      flex-direction:column !important
    }
  }
  
  .ce-ant-dropdown-menu {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .ce-ant-modal-close-x {
    width: 54px;
    height: 54px;
    line-height: 54px;
  }
  
  .ce-ant-dropdown-menu-title-content {
    color: #4f537b;
    font-size: 14px;
  }
  .ce-ant-dropdown-menu-item {
    height: 48px;
  }
  .ce-ant-input:focus,
  .ce-ant-input-focused {
    border-color: #e5e5e5;
    box-shadow: 0 0 0 0px rgb(29 165 122 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
  .ce-ant-input:hover {
    border-color: #e5e5e5;
    border-right-width: 1px;
  }

  .check-trial-in-course-modal {
    .ce-ant-modal-body {
      padding: 0 !important;
      .check-trial-in-course-modal-header {
        border: none;
        border-bottom: 1px solid rgba(184, 187, 193, 0.3);
        line-height: 125%;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .ce-ant-modal-confirm-btns {
      padding: 1rem 1.5rem;
      border-top: 1px solid rgba(184, 187, 193, 0.3);
    }
  }
  .ce-ant-select-item{
    font-size: 14px !important;
  }
  .ce-ant-picker-input > input {
    font-size: 14px !important;
  }
}



@primary-color: #5B9DFF;@ant-prefix: ce-ant;